import React from "react"
import axios from 'axios'
import { Loading, Clock, Check, UnCheck, Question, Note,  NoNote, Close, Edit, Down } from '../../images'
import './index.scss'
import { ReactHeight } from 'react-height';
import { isBrowser, sendSlackMessage } from '../../utils/Functions'
import { getCurrentUser } from '../../utils/auth'
import GetInitials from '../GetInitials'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      settings: [],
      page: 'overview',
      boxHeightCalc:0,
      boxHeight:0,
      okrJson: [],
      showModal: false,
      user:false,
      url: '',
      okrNumber: null,
    }
  }

  loadData() {
    const user = getCurrentUser()
    //console.log('user:',user)
    const url = `${process.env.WP_ADDRESS}/wp-json/wp/v2/okr?slug=${this.props.splat}&_embed`
    const settingsUrl = `${process.env.WP_ADDRESS}/wp-json/acf/v2/options`
    //console.log('settingsUrl:',settingsUrl)
    //console.log('url:',url)
    axios.all([
      axios.get(url),
      axios.get(settingsUrl)
    ])
    .then(axios.spread((res, setting) => {
      if (res.data[0]) {
        //console.log('res.data[0]:',res.data[0])
        this.setState({
          settings: setting.data.acf,
          loading: false,
          boxHeight: null,
          okrJson: res.data[0],
          newNote: '',
          openNoteKey: null,
          addNote: '',
          user: user,
          url: `${process.env.OKR_URL}/okr/${res.data[0].slug}/`,
          slackUser: res.data[0]._embedded.author[0].acf.slackUserName ? `@${res.data[0]._embedded.author[0].acf.slackUserName}` : '',
          okrNumber: res.data[0].acf.okrNumber
        })
        //
        //console.log('res.data:',res.data[0]._embedded.author[0].acf.slackUserName)
        //const title = `OKR being read ${this.state.okrNumber && `OKR-${this.state.okrNumber}`}`
        const slackUser = this.state.slackUser ? `<${this.state.slackUser}> ` : ``
        const message = `${slackUser}OKR-${this.state.okrNumber} being read :eyes: <${this.state.url}>`
        this.props.preview !== true && sendSlackMessage('okr', message)

      }else{
        this.setState({
          loading: false,
          error: true,
        })
      }
    }))
    .catch((err) => {
      console.log('error:', err)
      this.setState({
        loading: false,
        error: true
      })
    })
  }

  componentWillMount() {
    if (isBrowser) {
      const urlParams = window.location.search ? new URLSearchParams(window.location.search) : null;
      const page = urlParams && urlParams.get('page');
      if (page) { this.setState({page:page}) }
    }
    this.loadData();
  }

  componentDidMount() {
    this.setState({boxHeight:this.state.boxHeightCalc})
  }

  componentWillUnmount() {
    this.setState({})
  }

  nivigateTo(event) {
    this.setState({
      boxHeight: null,
      [event.target.name]: event.target.value,
      page: event.target.value
    })
    //navigate(`/okr/${this.props.splat}?page=${event.target.value}`)
  }

  toggleComplete = (event, resultKey) => {
    event.preventDefault();
    if (this.props.preview === true) {
      console.log('Toggle Complete:',resultKey)
      const oldArray = this.state.okrJson
      const keyIndex = resultKey.split("-")
      const oldValue = oldArray.acf.strategy[keyIndex[0]].objectiveResults[keyIndex[1]].results[keyIndex[2]].done
      const newValue = !oldValue
      oldArray.acf.strategy[keyIndex[0]].objectiveResults[keyIndex[1]].results[keyIndex[2]].done = newValue
      this.setState({
        okrJson: oldArray
      })
      const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/secure/v2/okr`;
      //console.log('wpPostMetaURL:',wpPostMetaURL)
      var params = {
        post: oldArray
      }
      axios.post(wpPostMetaURL, params)
      .then(response => {
        console.log('The post is up to date.')
      })
      .catch(error => console.error('submitForm Error', error));
    }else{
      console.log('Nopte!')
    }
  }

  handleSubmit = (e, message) => {
     e.preventDefault()
     //console.log('this.state.user:',this.state.user)
     const user = this.state.user && this.state.user.name ? this.state.user.name : 'Client'
     const noteArray = {
       note: this.state.note,
       user: user
     }
     //console.log('okrJson', this.state.okrJson)
     const oldArray = this.state.okrJson
     const keyIndex = this.state.openNoteKey.split("-")

     oldArray.acf.strategy[keyIndex[0]].objectiveResults[keyIndex[1]].results[keyIndex[2]].notes[parseInt(keyIndex[3])+1] = noteArray
     //console.log('oldArray:',oldArray)
     //console.log('this.state.okrJson:',this.state.okrJson)

     const theResult = oldArray.acf.strategy[keyIndex[0]].objectiveResults[keyIndex[1]].results[keyIndex[2]].result
     const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/secure/v2/okr`;
     //console.log('wpPostMetaURL:',wpPostMetaURL)
     var params = {
       post: oldArray
     }
     axios.post(wpPostMetaURL, params)
     .then(response => {
       //console.log('response:',response.data)
       //const title = `OKR note added ${this.state.okrNumber && `OKR-${this.state.okrNumber}`}`
       const slackUser = this.state.slackUser ? `<${this.state.slackUser}> ` : ``//The author
       const message = `${slackUser}OKR-${this.state.okrNumber} note added :speech_balloon: ${theResult} - *${this.state.user.name ? this.state.user.name : 'client'}* "${this.state.note}" <${this.state.url}>`
       sendSlackMessage('okr', message)

       this.setState({
         showModal: false,
         note: '',
       })
     })
     .catch(error => console.error('submitForm Error', error));
  }

  //Form change
  handleChange = (e) => {
   let newState = {}
   newState[e.target.name] = e.target.value
   this.setState(newState)
  }

  addNote = ( event, openNoteKey ) => {
    event.preventDefault();
    const { openNoteKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNoteKey) return this.setState({ openNoteKey: null });
    return this.setState({ openNoteKey: openNoteKey });
  }

  render() {
    const { loading, error, settings = [], page, okrJson, openNoteKey, user } = this.state
    const { closeOkr, preview = false } = this.props
    const { goalDescription, objectiveDescription, keyResultDescription } = settings
    const { acf = [], id } = okrJson

    if ( loading ) {
      return <div className="loading"><Loading/></div>;
    }

    if ( error ) {
      return (
        <div className="error-msg">
          <div>
            <h4>This provided link has expired.</h4>
            <p>A new link can be provided by contacting the original sender.</p>
          </div>
        </div>
      )
    }


    if (settings && okrJson) {
      const { strategyLabel, who, goal, strategy } = acf
      if (strategy.length > 0) {

        const { objectiveResults } = page === 'overview' ? {objectiveResults: []}  : strategy[page]
        const width = 100 / objectiveResults.length
        const alpha = ['A','B','C','D','E'];
        const boxStyle = {
          height: this.state.boxHeight ? `${this.state.boxHeight}px` : ``
        }
        return (
            <div className="okrs">

              {preview &&
                <div className="address">
                  <div className="inner">
                    <span>
                      Client Link: <a href={`${process.env.OKR_URL}/okr/${this.props.splat}`} rel="noopener noreferrer" target="_blank">{process.env.OKR_URL}/okr/{this.props.splat}</a> (This link will send you a slack notification when read.)
                    </span>
                    <button title="Close OKR" onClick={() => closeOkr()} className="close"><Close/></button>
                    <a target="_blank" rel="noopener noreferrer" href={`${process.env.WP_ADDRESS}/wp-admin/post.php?auto_login=${user.cookie}&post=${id}&action=edit&classic-editor`} title="Edit" className="edit"><Edit/></a>
                  </div>
                </div>
              }

              <div className="header">

                <div className="wrap">

                  <div className="col">
                    <h2>{who}</h2>
                    <p>{strategyLabel && strategyLabel}</p>
                  </div>

                  <div className="col">
                    <div className="menu">
                      <select name="page" onChange={e => this.nivigateTo(e)} value={this.state.page}>
                        <option value="overview">Overview</option>
                        {strategy && strategy.map((stra, key) => (
                          <option key={key} value={key}>{stra.strategyTitle}</option>
                        ))}
                      </select>
                      <Down/>
                    </div>
                  </div>

                </div>

              </div>

              {this.state.page === 'overview' ?

                <div className="overview">
                  <div className="wrap">
                    <div className="grid"><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/></div>
                    <div className="goal"><span>GOAL</span>{goal}</div>
                    <div className="quarter-list">
                      {strategy && strategy.map((strat, index) =>
                        <div className="quarter" key={index} onClick={e => this.setState({page: index})}>
                          <Down/>
                          <div className="strategy">{strat.strategyTitle}</div>
                          <div className="objectives">
                            {strat.objectiveResults && strat.objectiveResults.map((objective, index) =>
                              <div className="objective" key={index}>{objective.objective}</div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

              :

              <div className="body">

                <div className="goal">

                  <div className="inner">
                    <div className="col title">
                      <h2>Goal</h2>
                      {goalDescription &&
                        <button className="note"><Question/>
                          <div className="the-note"><p>{goalDescription && goalDescription}</p></div>
                        </button>
                      }
                    </div>
                    <div className="col">
                      <p>{goal && goal}</p>
                    </div>
                  </div>

                </div>

                <div className="objective-results">
                  <div className="wrap">

                    <div className="title">
                      <h3>Objectives</h3>
                      {objectiveDescription &&
                        <button className="note"><Question/>
                          <div className="the-note">{objectiveDescription && objectiveDescription}</div>
                        </button>
                      }
                    </div>

                    <div className="inner">
                      {objectiveResults && objectiveResults.map((object, oRkey) => {
                        const { objective, results } = object
                        const objResKey = `${page}-${oRkey}`
                        return (
                          <div className="col" key={oRkey} style={{ width: `${width}%` }}>
                            <div className="results">
                              <ReactHeight onHeightReady={height => this.setState({boxHeight:height})} className="objective" style={boxStyle}>
                                <h2>{alpha[oRkey]}</h2>
                                <div className="scroll"><p>{objective}</p></div>
                              </ReactHeight>
                              <div className="results-title">
                                <h4>Key Results</h4>
                                {keyResultDescription &&
                                  <button className="note"><Question/>
                                    <div className="the-note">{keyResultDescription}</div>
                                  </button>
                                }
                              </div>
                              {results && results.map((res, rEkey) => {
                                const { result, done, notes, time} = res
                                const resultKey = `${objResKey}-${rEkey}-${notes.length}`
                                return (
                                  <div key={rEkey} className="result">


                                    <div onClick={event => this.toggleComplete(event, resultKey)} className={`status${done ? ' done' : ''}${preview ? ' can-edit' : ''}`}>{done ? <Check/> : <UnCheck/>}</div>

                                    <div className="result-text">{result}</div>

                                    {notes.length > 0 ?
                                      <button className="note full" onClick={event => this.addNote(event, resultKey)}>{notes.length}<Note/>
                                        <div className="the-note">
                                          {notes.map((note, nOkey) => {
                                            const noteKey = `${resultKey}-${nOkey}`
                                            return (
                                              <div key={noteKey} className="item"><GetInitials name={note.user}/> <p>{note.note}</p></div>
                                            )
                                          })}
                                        </div>
                                      </button>
                                    :
                                      <button className="note empty" onClick={event => this.addNote(event, resultKey)}><NoNote/></button>
                                    }

                                    {openNoteKey === resultKey &&
                                    <div className="modal">
                                      <div className="inner">
                                        <div className="old-notes">
                                        {notes.map((note, nOkey) => {
                                          const noteKey = `${resultKey}-${nOkey}`
                                          return (
                                            <div key={noteKey} className="item"><GetInitials name={note.user}/> <p>{note.note}</p></div>
                                          )
                                        })}
                                        </div>
                                        <div className="form">
                                          <form onSubmit={event => {this.handleSubmit(event)}}>
                                            <textarea name="note" placeholder="Add a note" onChange={e => this.handleChange(e)} value={this.state.note} />
                                            <button>Save</button>
                                          </form>
                                          <button onClick={event => this.addNote(event, resultKey)} className="close"><Close/></button>
                                        </div>
                                      </div>
                                    </div>
                                    }

                                    {preview && time && <div className="time">{time}<Clock/></div>}
                                  </div>

                                )

                              })}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              }
            </div>
        )
      } else {
        return <div className="okrs">You need to create a strategy</div>
      }
    }
  }
}

export default Index
